import ArrowIcon from "@assets/images/end-icon.svg";
import YellowArrowIcon from "@assets/images/arrow-see-all-yellow.svg";
import CrownIcon from "@assets/images/crown-icon.svg";
import TvShowIcon from "@assets/images/tv-show.svg";
import TvShowIconSelected from "@assets/images/tv-show-selected.svg";
import { URL } from "@constants/routeConstants";
import { MENU_LIST } from "@constants";
import MIXPANEL from "@constants/mixpanel";

export const ACCOUNT_DROPDOWN_LIST = [{
    'name': 'switchAccount',
    'displayName': 'Switch Account',
    'leftIcon': 'icon-switch-account',
    'rightIcon': 'icon-down',
    'rightIconOnHover': 'icon-right',
    'isRedirectionReq': false,
}, {
    'name': 'editProfile',
    'displayName': 'Edit Profile',
    'reDirectTo': `/${URL.PROFILE}`,
    'leftIcon': 'icon-profile',
    'rightIcon': 'icon',
}, {
    'displayName': 'My Subscription',
    'reDirectTo': `/${URL.MY_SUBSCRIPTION}`,
    'leftIcon': 'icon-my-subscription-1',
    'rightIcon': 'icon',
}, /*{
        'displayName': 'Transaction History',
        'reDirectTo': `/${URL.TRANSACTION_HISTORY}`,
        'leftIcon': 'icon-trans-history',
        'rightIcon': 'icon',
    },
    {
        'displayName': 'My Offers',
        'reDirectTo': `/${URL.MY_OFFERS}`,
        'leftIcon': 'icon-my-offers',
        'rightIcon': 'icon',
    },
    {
        'displayName': 'Link My Tata Sky Accounts',
        'reDirectTo': `/${URL.LINK_ACCOUNT}`,
        'leftIcon': 'icon-link-accounts',
        'rightIcon': 'icon',
    },*/
{
    'displayName': 'Device Management',
    'reDirectTo': `/${URL.DEVICE_MANAGEMENT}`,
    'leftIcon': 'icon-device-management',
    'rightIcon': 'icon',
}, /*{
        'displayName': 'Notification Settings',
        'reDirectTo': `/${URL.NOTIFICATION_SETTINGS}`,
        'leftIcon': 'icon-notification_settings',
        'rightIcon': 'icon',
        'isLayeredIcon': true,
    },*/];

export const ACTION = {
    GET_ANONYMOUS_ID: 'GET_ANONYMOUS_ID',
    HEADER_CONTENT: 'HEADER_CONTENT',
    FETCH_PROFILE: 'FETCH_PROFILE',
    ADD_ALIAS: 'ADD_ALIAS',
    LOGIN_POPUP_STATE: 'LOGIN_POPUP_STATE',
    SEARCH_STATUS: 'SEARCH_STATUS',
    SEARCH_TEXT: 'SEARCH_TEXT',
    RECENT_SEARCH: 'RECENT_SEARCH',
    SEARCH_SOURCE: 'SEARCH_SOURCE',
    ACCOUNT_DROPDOWN: 'ACCOUNT_DROPDOWN',
    SWITCH_ACCOUNT_DROPDOWN: 'SWITCH_ACCOUNT_DROPDOWN',
    NOTIFICATION_DROPDOWN: 'NOTIFICATION_DROPDOWN',
    CALL_CONFIG: 'CALL_CONFIG',
    FETCH_PROFILE_ERROR: 'FETCH_PROFILE_ERROR',
    ADD_ALIAS_ERROR: 'ADD_ALIAS_ERROR',
    GET_RRM_SESSION_INFO: 'GET_RRM_SESSION_INFO',
    CLEAR_STORE: 'CLEAR_STORE',
    GET_FAQ: 'GET_FAQ',
    GET_GENRE: 'GET_GENRE',
    GET_CATEGORIES_LIST: 'GET_CATEGORIES_LIST',
    CATEGORIES_DROPDOWN: 'CATEGORIES_DROPDOWN',
    HEADER_DOWNLOAD: 'HEADER_DOWNLOAD',
    IS_HOME_PAGE: 'IS_HOME_PAGE',
    ACCOUNT_REFRESH: 'ACCOUNT_REFRESH',
    ACCOUNT_REFRESH_OLD_STACK: 'ACCOUNT_REFRESH_OLD_STACK',
    MANAGED_APP_PUSH: 'MANAGED_APP_PUSH',
    GET_AUTO_SUGGESTION_DATA: 'GET_AUTO_SUGGESTION_DATA',
    SHOW_AUTO_SUGGESTION: 'SHOW_AUTO_SUGGESTION',
    MEDIA_READY_POLLING: "MEDIA_READY_POLLING",
    ACCOUNT_PUBNUB_CHANNEL_DETAIL: 'ACCOUNT_PUBNUB_CHANNEL_DETAIL',
    IS_NEW_HERO_BANNER_ENABLED: 'IS_NEW_HERO_BANNER_ENABLED'
};

export const HEADER_MENU_LIST = [{
    pageName: "Home",
    pageType: "DONGLE_HOMEPAGE",
    position: 1,
    searchPageName: "HOME",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    iconName: 'icon-home',
    linkToRedirect: URL.HOME,
    accessBeforeLogin: true,
    menuNameToDisplay: "Home"
}, {
    pageName: "Movies",
    pageType: "DONGLE_MOVIES_1",
    position: 2,
    searchPageName: "MOVIES",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    iconName: 'icon-movies_in_active',
    linkToRedirect: URL.MOVIES,
    accessBeforeLogin: true,
    menuNameToDisplay: "Movies"
}, {
    pageName: "Shows",
    pageType: "DONGLE_TVSHOWS",
    position: 3,
    searchPageName: "Shows",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    // iconName: 'icon-tv-show',
    linkToRedirect: URL.TV_Shows,
    accessBeforeLogin: true,
    menuNameToDisplay: "Shows",
    imagePath: TvShowIcon,
    selectedImagePath: TvShowIconSelected,
    headerItemclass: "shows-icon"
}, {
    pageName: "Sports",
    pageType: "BINGE_ANYWHERE_SPORTS",
    position: 4,
    searchPageName: "SPORTS",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    iconName: 'icon-sports',
    linkToRedirect: URL.SPORTS,
    accessBeforeLogin: true,
    menuNameToDisplay: "Sports",
},
{
    pageName: "LiveTV",
    pageType: "DONGLE_LIVETV",
    position: 5,
    searchPageName: "Live TV",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    iconName: 'icon-live-tv',
    linkToRedirect: URL.LIVE_TV,
    accessBeforeLogin: true,
    menuNameToDisplay: "Live TV"
}];

export const CATEGORIES_PAGE_DETAIL = {
    pageName: "Categories",
    pageType: "DONGLE_TVSHOWS",
    position: 4,
    searchPageName: "Categories",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    iconName: 'icon-Category-upd',
    linkToRedirect: URL.CATEGORIES,
    accessBeforeLogin: true,
};

export const HEADER_MENU_LIST_GO_VIP = {
    pageName: "Subscribe",
    pageType: "DONGLE_TVSHOWS",
    position: 4,
    searchPageName: "Subscribe",
    subPage: false,
    subPageImage: "",
    subPageType: "",
    linkToRedirect: URL.SUBSCRIPTION,
    loginOnClick: true,
    accessBeforeLogin: true,
    showCrown: true,
};
export const MENU_LIST_FIRST_LABEL = {
    HELP_AND_SUPPORT: 'Help & Support',
};

export const SIDE_BAR_MENU_LIST = [{
    firstLabel: MENU_LIST.BINGE_LIST,
    iconName: 'icon-Binge-list',
    linkToRedirect: URL.WATCHLIST,
    endIconPath: ArrowIcon,
    accessBeforeLogin: false,
    webView: true,
    mobileView: true,
    mixpanelLoginSource: MIXPANEL.VALUE.BINGE_LIST,
    mixpanelValue : MIXPANEL.VALUE.SUBMENU_BINGELIST,
}, {
    firstLabel: 'Settings',
    iconName: 'icon-settings1',
    endIconPath: ArrowIcon,
    linkToRedirect: true,
    accessBeforeLogin: false,
    webView: true,
    mobileView: true,
    mixpanelLoginSource: MIXPANEL.VALUE.SETTINGS,
    mixpanelValue : MIXPANEL.VALUE.SUBMENU_SETTINGS,
}, {
    firstLabel: MENU_LIST_FIRST_LABEL.HELP_AND_SUPPORT,
    iconName: 'icon-Help-Grey',
    linkToRedirect: URL.HELP_CENTER,
    endIconPath: ArrowIcon,
    accessBeforeLogin: true,
    webView: true,
    mobileView: true,
    mixpanelValue : MIXPANEL.VALUE.SUBMENU_HELP,
}];

export const SIDE_MENU_HEADERS = {
    SUBSCRIBE: 'Subscribe',
    MY_PLAN: 'My Plan',
    TATA_SKY_BALANCE: 'Tata Play Balance',
    ACTIVATE_PRIME_MENU:"Activate Prime",
    ACTIVATE_APPLE_MENU: 'Activate Apple TV+',
};

export const GO_VIP_DATA = {
    firstLabel: SIDE_MENU_HEADERS.SUBSCRIBE,
    linkToRedirect: `${URL.SUBSCRIPTION}`,
    endIconPath: YellowArrowIcon, // iconName: 'icon-Crown',
    leftIconPath: CrownIcon,
    firstIconClassName: 'crown-icon',
    firstLabelClassName: 'go-vip',
    funcClick: false,
    webView: true,
    mobileView: true,
    mixpanelValue: MIXPANEL.VALUE.SUBMENU_SUBSCRIBE
};

export const AUTO_SUGGESTION_SUGGESTOR = {
    KEYWORD_SUGGESTOR: 'keywordsuggestor',
    TITILE_SUGGESTOR: 'titlesuggestor',
    GENRE_SUGGESTOR: 'genresuggestor',
    PROVIDER_SUGGESTOR: 'providersuggestor',
    LANGUAGE_SUGGESTOR: 'languagesuggestor',
}

export const AUTO_SUGGESTION_SUGGESTOR_ARRAY = ['keywordsuggestor', 'titlesuggestor', 'genresuggestor', 'providersuggestor', 'languagesuggestor'];

export const MIXPANEL_SUGGESTOR_TYPE = {
    STRING: 'STRING',
    GENRE: 'GENRE',
    PARTNER: 'PARTNER',
    LANGUAGE: 'LANGUAGE',
    CONTENT: 'CONTENT'
}

export const SEARCH_DEFAULT_THRESHOLD = {
    TWO: '2',
};
