import { generateUuidV4String, getEnvironmentConstants, getRandom4DigitNo } from "@utils/common";
import { getKey } from "@utils/storage";
import { LOCALSTORAGE } from "./index";

export const ERROR_MESSAGES = {
    CONTENT_UNAVAILABLE: 'Something went wrong.Unable to play content',
    TVOD_CONTENT_EXPIRED: 'You have no longer access to view this content',
    NO_INTERNET: 'No Internet Connection',
    DEFAULT_ERROR_HEADING: 'Video unavailable',
    DEFAULT_ERROR_MESSAGE: 'We are unable to play your video right now. Please try again in a few minutes',
    DEVICE_REMOVED: 'Device Removed',
    DEVICE_REMOVED_MESSAGE: 'You have been logged out of this device. Please log in again.',
    CONCURRENCY: 'You have exceeded the limit of screens on which you can watch this video. Please stop playback on one of the other devices to watch here.',
};

export const PLAYER_CODES = {
    ERROR_2003: 2003, //"DRM_FAILED_LICENSE_REQUEST"
    ERROR_403: 403, // Forbidden: Access is denied.
    ERROR_401: 401, //Unauthorized: Access is denied due to invalid credentials.
    ERROR_130301: 130301, // Geo-blocking
    ERROR_130302: 130302, // Proxy detection
    ERROR_130401: 130401, // CSM concurrency
    ERROR_100209: 100209, // JWT-token expiry
};

export const PROVIDER_NAME = {
    SHEMAROOME: 'shemaroome',
    TATASKY: 'tatasky',
    VOOT: 'voot',
    CURIOSITY_STREAM: 'curiositystream',
    VOOTSELECT: 'vootselect',
    VOOTKIDS: 'vootkids',
    ZEE5: 'zee5',
    HOTSTAR: 'hotstar',
    PRIME: 'prime',
    SONYLIV: 'sonyliv',
    SUNNXT: 'sunnxt',
    EROS_NOW: 'erosnow',
    HUNGAMA: 'hungama',
    DOCUBAY: 'docubay',
    EPICON: 'epicon',
    HOICHOI: 'hoichoi',
    PLANET_MARATHI: 'planetmarathi',
    CHAUPAL: 'chaupal',
    MX_PLAYER: 'mxplayer',
    LIONS_GATE: 'lionsgate',
    TRAVEL_XP: 'travelxp',
    NAMMAFLIX: 'nammaflix',
    GAMEZOP: 'gamezop',
    APPLE: "appletv",
    AHA: 'aha',
    VROTT: 'vrott',
    JIO_CINEMA: 'jiocinema',
    HALLMARKMOVIESNOW: 'hallmarkmoviesnow',
    ZEE_FIVE: 'zeefive',
    DISTRO_TV: 'distrotv',
    MANORAMA_MAX: "manoramamax",
    DISCOVERY_PLUS: 'discoveryplus',
    JIO_HOTSTAR: 'jiohotstar'
};


export const SHEMAROO_ANALYTICS_EVENT = {
    PLAY: 'PLAY',
    BUFFERING: 'BUFFERING',
    REGULAR_INTERVAL: 'REGULAR_INTERVAL',
};

export const INTEGRATED_PARTNER_LIST = [
    PROVIDER_NAME.SHEMAROOME,
    PROVIDER_NAME.VOOTSELECT,
    PROVIDER_NAME.VOOTKIDS,
    PROVIDER_NAME.TATASKY,
    PROVIDER_NAME.CURIOSITY_STREAM,
    PROVIDER_NAME.ZEE5,
    PROVIDER_NAME.HOTSTAR,
    PROVIDER_NAME.HUNGAMA,
    PROVIDER_NAME.EPICON,
    PROVIDER_NAME.DOCUBAY,
    PROVIDER_NAME.EROS_NOW,
    PROVIDER_NAME.SONYLIV,
    PROVIDER_NAME.PLANET_MARATHI,
    PROVIDER_NAME.CHAUPAL,
    PROVIDER_NAME.HOICHOI,
    PROVIDER_NAME.LIONS_GATE,
    PROVIDER_NAME.MX_PLAYER,
    PROVIDER_NAME.GAMEZOP,
]

export const PLAY_AUTH_TYPE = {
    JWT_TOKEN: 'JWTToken',
    DRM_PLAYBACK: "DRM_TokenAPI",
    NONE: "None",
    UNKNOWN: "Unknown",
    DEEP_LINK: "Deeplink",
    DRM_LICENSED_TOKEN: "DRM_Licensed_Token",
};

export const GENERIC_DEVICE_OS = {
    ANDROID: 'Android',
    IOS: 'iOS',
};

export const APPLE_REDEMPTION_STATUS = {
    CONSUMED: 'consumed',
    PENDING: 'pending',
    NOT_INITIATED: 'notinitiated',
    ENTITLED: 'ENTITLED',
    ACTIVATED: 'ACTIVATED',
    REACTIVATION_RENEW: 'REACTIVATION_RENEW',
    REACTIVATION_MIGRATION: 'REACTIVATION_MIGRATION',
    ENTITLEMENT_INITIATED: 'ENTITLEMENT_INITIATED',
}

export const LIVE_LABEL = {
    GO_LIVE: 'Go Live',
    LIVE: 'Live',
};

export const SUBTITLE_TEXT = {
    NONE: 'None',
};

export const SHOW_APPLE_STATUS = {
    APPLE_CTA: 'apple-cta',
    APPLE_POPUP: 'apple-popup',
}

export const MX_PLAYER_CONTENT_TYPE = {
    MOVIE: 'movie',
    SHORTS: 'shorts',
    EPISODE: 'episode',
};

export const PROVIDER_EVENT_TYPE = {
    LOGIN_SUCCESS: 'LOGIN-SUCCESS',
    PLAYBACK_START: 'PLAYBACK-START',
};

export const PROVIDER_CW = {
    ALL: 'ALL',
};

export const LIVE_PLAYBACK_TYPE = {
    LIVE_DEFAULT: 'live_default',
    DAI: 'dai',
    CUSTOM: 'custom',
};

export const DISTRO_MACRO_KEY = {
    CACHE_BUSTER: '__CACHE_BUSTER__',
    ENV_I: "__env.i__",
    ENV_U: "__env.u__",
    APP_BUNDLE: "__APP_BUNDLE__",
    STORE_URL: "__STORE_URL__",
    APP_CATEGORY: "__APP_CATEGORY__",
    APP_VERSION: "__APP_VERSION__",
    APP_NAME: "__APP_NAME__",
    APP_DOMAIN: "__APP_DOMAIN__",
    WIDTH: "__WIDTH__",
    HEIGHT: "__HEIGHT__",
    DEVICE_ID: "__DEVICE_ID__",
    LIMIT_AD_TRACKING: "__LIMIT_AD_TRACKING__",
    IS_GDPR: "__IS_GDPR__",
    IS_CCPA: "__IS_CCPA__",
    ADVERTISING_ID: "__ADVERTISING_ID__",
    USER_AGENT: "__USER_AGENT__",
    DEVICE: "__DEVICE__",
    DEVICE_ID_TYPE: "__DEVICE_ID_TYPE__",
    DEVICE_CONNECTION_TYPE: "__DEVICE_CONNECTION_TYPE__",
    CLIENT_IP: "__CLIENT_IP__",
    GEO_COUNTRY: "__GEO_COUNTRY__",
    LATITUDE: "__LATITUDE__",
    LONGITUDE: "__LONGITUDE__",
    GEO_TYPE: "__GEO_TYPE__",
    GEO_DMA: "__GEO_DMA__",
    PAGEURL_ESC: "__PAGEURL_ESC__",
    PALN: "__PALN__",
    DEVICE_MAKE: "__DEVICE_MAKE__",
    PLAYBACK_ID: "__PLAYBACK_ID__",
    DEVICE_CATEGORY: "__DEVICE_CATEGORY__",
};

export const DISTRO_MACRO_VALUES = {
    CATEGORY_ENTERTAINMENT: "entertainment",
    VIDEO_WIDTH: "1920",
    VIDEO_HEIGHT: "1080",
    ENABLED: "1",
    DISABLED: "0",
    COOKIE: "cookie",
    CABLE: "cable",
    INDIA_ISO_CODE: "IN",
    APP_VALUE: 'tataplaybinge',
    DEVICE_VALUE: 'WEB',
    PARTNER_VALUE: "tataplay",
    WEB_LARGE_DEVICE_NAME: 'WebDesktop',
    WEB_SMALL_DEVICE_NAME: 'WebMobile',
    APP_VALUE_PROD: 'tataplay',
}

export const DISTRO_MACRO_ARRAY = [
    {
        MACRO_KEY: DISTRO_MACRO_KEY.CACHE_BUSTER,
        MACRO_VALUE: () => {
            return getRandom4DigitNo();
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.ENV_I,
        MACRO_VALUE: () => {
            return generateUuidV4String();
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.ENV_U,
        MACRO_VALUE: () => {
            return generateUuidV4String();
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.APP_BUNDLE,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.STORE_URL,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.APP_CATEGORY,
        MACRO_VALUE: DISTRO_MACRO_VALUES.CATEGORY_ENTERTAINMENT,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.APP_VERSION,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.APP_NAME,
        MACRO_VALUE: DISTRO_MACRO_VALUES.APP_VALUE,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.APP_DOMAIN,
        MACRO_VALUE: () => {
            return getEnvironmentConstants().ENV_URL;
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.WIDTH,
        MACRO_VALUE: DISTRO_MACRO_VALUES.VIDEO_WIDTH,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.HEIGHT,
        MACRO_VALUE: DISTRO_MACRO_VALUES.VIDEO_HEIGHT,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.DEVICE_ID,
        MACRO_VALUE: () => {
            return generateUuidV4String();
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.LIMIT_AD_TRACKING,
        MACRO_VALUE: DISTRO_MACRO_VALUES.DISABLED,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.IS_GDPR,
        MACRO_VALUE: DISTRO_MACRO_VALUES.DISABLED,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.IS_CCPA,
        MACRO_VALUE: DISTRO_MACRO_VALUES.DISABLED,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.ADVERTISING_ID,
        MACRO_VALUE: () => {
            return generateUuidV4String();
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.USER_AGENT,
        MACRO_VALUE: window.navigator.userAgent,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.DEVICE,
        MACRO_VALUE: DISTRO_MACRO_VALUES.DEVICE_VALUE,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.DEVICE_ID_TYPE,
        MACRO_VALUE: DISTRO_MACRO_VALUES.COOKIE,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.DEVICE_CONNECTION_TYPE,
        MACRO_VALUE: DISTRO_MACRO_VALUES.CABLE,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.CLIENT_IP,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.GEO_COUNTRY,
        MACRO_VALUE: DISTRO_MACRO_VALUES.INDIA_ISO_CODE,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.LATITUDE,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.LONGITUDE,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.GEO_TYPE,
        MACRO_VALUE: DISTRO_MACRO_VALUES.ENABLED,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.GEO_DMA,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.PAGEURL_ESC,
        MACRO_VALUE: '',
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.PALN,
        MACRO_VALUE: ()=> {
            return getKey(LOCALSTORAGE.PALN_FOR_NON_DAI);
        },
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.DEVICE_MAKE,
        MACRO_VALUE: DISTRO_MACRO_VALUES.WEB_LARGE_DEVICE_NAME,
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.PLAYBACK_ID,
        MACRO_VALUE: () => {
            return generateUuidV4String();
        }
    },
    {
        MACRO_KEY: DISTRO_MACRO_KEY.DEVICE_CATEGORY,
        MACRO_VALUE: DISTRO_MACRO_VALUES.DEVICE_VALUE.toLowerCase()
    },
];

/**
e: "event_name"
ep: "episode_id"
f: "dai_asset_key"
i: "dai_session_id"
m: "appname"
p: "content_provider_id"
r: "random"
show: "show_id"
u: "device_id"
v: "dai_session_id"
**/

export const DISTRO_TRACK_EVENT_KEY = {
    RANDOM: 'random', // r
    EVENT_NAME: 'event_name', //e
    DEVICE_ID: 'device_id', // u
    DAI_SESSION_ID: 'dai_session_id', // i
    DAI_ASSET_KEY: 'dai_asset_key', // f
    APPNAME: 'appname', // m
    CONTENT_PROVIDER_ID: 'content_provider_id', // p
    SHOW_ID: 'show_id',  // show
    EPISODE_ID: 'episode_id', //ep
    MSG: 'msg', //msg,
    PARTNER_NAME: "partner_name", //dpname
    DEVICE_CATEGORY: "device_category" // dv
};

export const DISTRO_TRACK_EVENT_ARRAY = [
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.RANDOM,
        TRACKING_VALUE: () => {
            return getRandom4DigitNo();
        }
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.EVENT_NAME,
        TRACKING_VALUE: '',
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.DEVICE_ID,
        TRACKING_VALUE: () => {
            return generateUuidV4String();
        }
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.DAI_SESSION_ID,
        TRACKING_VALUE: '',
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.DAI_ASSET_KEY,
        TRACKING_VALUE: '',
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.APPNAME,
        TRACKING_VALUE: DISTRO_MACRO_VALUES.APP_VALUE,
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.CONTENT_PROVIDER_ID,
        TRACKING_VALUE: '',
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.SHOW_ID,
        TRACKING_VALUE: '',
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.EPISODE_ID,
        TRACKING_VALUE: '',
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.PARTNER_NAME,
        TRACKING_VALUE: DISTRO_MACRO_VALUES.PARTNER_VALUE,
    },
    {
        TRACKING_KEY: DISTRO_TRACK_EVENT_KEY.DEVICE_CATEGORY,
        TRACKING_VALUE: DISTRO_MACRO_VALUES.DEVICE_VALUE.toLowerCase()
    },
]

export const DISTRO_EVENT_NAME = {
    FF: 'ff',
    VPLAY: 'vplay',
    VS: 'vs',
    ERR: 'err',
}

export const DISTRO_TRACKING_URL = "https://i.jsrdn.com/i/1.gif?dpname=partner_name&r=random&e=event_name&u=device_id&i=dai_session_id&v=dai_session_id&f=dai_asset_key&m=appname&p=content_provider_id&show=show_id&ep=episode_id&dv=device_category";

export const PRIME_ENTITLEMENT_STATUS = {
    PENDING: 'pending',
    ENTITLED: 'entitled',
    ACTIVATED: 'activated',
    SUSPEND: 'suspend',
    CANCELLED: 'cancelled',
};

export const AMAZON_WEB_LARGE_VERBIAGE = {
    SUB_HEADER: "Prime Video content can be viewed on Tata Play Binge App on your Mobile or TV. ",
    BUTTOM_CTA: 'OK',
}

export const BM_PLAYER_VERSION = "8.96.0";

export const MAX_HIGH_WATCH_DURATION = 360;
