import store from "@src/store";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import * as moment from "moment";

import { openPopup, closePopup } from "@common/Modal/action";
import { URL } from "@constants/routeConstants";
import {
    isMobile,
    isWebSmallLinkPayment,
    isUserloggedIn,
    showLanguageOnboardingPopup,
    loginInFreemium,
    isHomePage,
    getSearchParamsAsObject,
    accountChannelToBeSubscribed,
    switchToAccountChannel,
    switchToRMNChannel,
} from "@utils/common";
import { fetchGlobalConfig, isHideDownloadHeaderAction } from "@components/Header/APIs/actions";
import { deleteKey, getKey, setKey } from "@utils/storage";
import { openLoginPopup } from "@containers/Login/APIs/actions";
import { LOCALSTORAGE, MINI_SUBSCRIPTION, APP_LAUNCH_COUNTER, MOBILE_BREAKPOINT, WEB_SMALL_PAYMENT_SOURCE, SESSION_STORAGE, DTH_TYPE, GLOBAL_CONFIG_SOURCE } from "@constants";
import { getPackListing, getNotLoggedInPack } from "@containers/Subscription/APIs/action";
import { checkCurrentSubscription } from "@containers/Subscription/APIs/subscriptionCommon";
import { handleNativeFlow } from "@containers/CampaignPage/APIs/campaignCommon";
import { BROWSE_TYPE } from "@containers/BrowseByDetail/APIs/constants";
import MIXPANEL from "@constants/mixpanel";
import FIREBASE from "@utils/constants/firebase";

export const getHomePageLaunchCounter = (current) => {
    let { location: { pathname } } = current?.props;
    const urlArr = pathname.split("/");
    let isHomePage = [URL.HOME, URL.DEFAULT].includes(urlArr[1]) || isEmpty(urlArr[1]);
    let counter = JSON.parse(getKey(LOCALSTORAGE.HOME_PAGE_LAUNCH_COUNTER));
    if (isHomePage) {
        if (!counter) {
            counter = APP_LAUNCH_COUNTER.ONE;
            let lastSubcriptionCounter = JSON.parse(getKey(LOCALSTORAGE.SHOW_SUBSCRIPTION_POPUP_COUNTER));
            lastSubcriptionCounter && deleteKey(LOCALSTORAGE.SHOW_SUBSCRIPTION_POPUP_COUNTER);
        } else {
            counter = counter + 1;
        }
        setKey(LOCALSTORAGE.HOME_PAGE_LAUNCH_COUNTER, counter);
    }
    return counter;
}

export const showLangOrSubPopup = async (current) => {
    let { location: { pathname }, history } = current?.props,
        sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
        isBingeTrial = !!sessionStorage.getItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW);

    if (!isHomePage(pathname) || sourceIsMSales || isBingeTrial) {
        return false;
    }

    const state = store.getState();
    // skip if login popup is already visible
    if (state?.loginReducer?.isLoginPopupVisible) {
        return
    }
    let drawerTimer = get(state.headerDetails, "configResponse.data.config.SubscriptionDrawer"),
        homePageLaunchCounter = getHomePageLaunchCounter(current),
        isFirstAppLaunch = homePageLaunchCounter === APP_LAUNCH_COUNTER.ONE,
        languagePopupAlreadyShowed = JSON.parse(getKey(LOCALSTORAGE.SHOW_LANGUAGE_POPUP_COUNTER)) === true,
        isLanguageUnSelected = showLanguagePopupInSmall(current);

    if (isFirstAppLaunch && !languagePopupAlreadyShowed) {
        if (isLanguageUnSelected) {
            showLanguageOnboardingPopup(current?.state?.windowContainerWidth, history)
            setKey(LOCALSTORAGE.SHOW_LANGUAGE_POPUP_COUNTER, true);
        } else if (drawerTimer?.openSubscriptionDrawer) {
            await showSubscriptionPopup(homePageLaunchCounter, current, isLanguageUnSelected);
        }

    } else if (drawerTimer?.openSubscriptionDrawer) {
        await showSubscriptionPopup(homePageLaunchCounter, current, isLanguageUnSelected)
    }
}

export const showSubscriptionPopup = async (homePageLaunchCounter, current, isLanguageUnSelected) => {
    let state = store.getState(),
        isManagedApp = get(state.headerDetails, "isManagedApp"),
        enableTickTickJourney = get(state.headerDetails, "configResponse.data.config.enableTickTickJourney"),
        drawerTimer = !isManagedApp ? get(state.headerDetails, "configResponse.data.config.SubscriptionDrawer") : (enableTickTickJourney ? get(state.headerDetails, "configResponse.data.config.tickTickDrawerScreen") : get(state.headerDetails, "configResponse.data.config.tickTickFixedPlanDrawerScreen")),
        secondLaunchFrequencyValue = get(state.headerDetails, "configResponse.data.config.SubscriptionDrawer");
    let { delayToOpenSubscriptionDrawer, loggedInDrawerFrequency = 1, guestDrawerFrequency = 1 } = drawerTimer,
        drawerFrequency = isUserloggedIn() ? loggedInDrawerFrequency : guestDrawerFrequency;

    let lastSubcriptionCounter = JSON.parse(getKey(LOCALSTORAGE.SHOW_SUBSCRIPTION_POPUP_COUNTER)),
        languagePopupAlreadyShowed = JSON.parse(getKey(LOCALSTORAGE.SHOW_LANGUAGE_POPUP_COUNTER)) === true;

    let isExpectedDrawerfrequency = homePageLaunchCounter == parseInt(drawerFrequency) + lastSubcriptionCounter + 1,
        isSecondAppLaunch = homePageLaunchCounter === (!isUserloggedIn() ? secondLaunchFrequencyValue?.firstLaunchGuestOpenSubscriptionDrawer : secondLaunchFrequencyValue?.firstLaunchLoginOpenSubscriptionDrawer),
        isFirstAppLaunch = homePageLaunchCounter === APP_LAUNCH_COUNTER.ONE,
        showSubscriptionModal = isExpectedDrawerfrequency || (isUserloggedIn() && isSecondAppLaunch) || ((languagePopupAlreadyShowed || !isLanguageUnSelected) && isFirstAppLaunch && isSecondAppLaunch && !isUserloggedIn()) || (!lastSubcriptionCounter && languagePopupAlreadyShowed && isSecondAppLaunch);
    if (showSubscriptionModal) {
        !isManagedApp && (isUserloggedIn() ? await store.dispatch(getPackListing()) : await store.dispatch(getNotLoggedInPack()));
        state = store.getState();
        let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data');
        let packListingData = get(state.subscriptionDetails, 'packListingData', []);
        let { location: { pathname } } = current?.props;
        const urlArr = pathname.split("/");
        let tickTick = get(state.headerDetails.configResponse, 'data.config.tickTickDrawerScreen.openTickTickDrawer')

        let isHelpCenterPage = [URL.HELP_CENTER].includes(urlArr[1]);
        let webSmallPaymentJourney = isWebSmallLinkPayment(location);
        let isValidUrl = (urlArr[1] === URL.HOME || !urlArr[1]) && !webSmallPaymentJourney && !isHelpCenterPage;

        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
        let shouldShowSubscription = isValidUrl && (isEmpty(userInfo) || (checkCurrentSubscription(currentSubscription)));
        shouldShowSubscription && setKey(LOCALSTORAGE.SHOW_SUBSCRIPTION_POPUP_COUNTER, homePageLaunchCounter);
        if (shouldShowSubscription && tickTick && isNotRedirectedFromManageApp() && isManagedApp) {
            setTimeout(async () => {
                await loginInFreemium({
                    openPopup,
                    closePopup,
                    openLoginPopup,
                    source: MIXPANEL.VALUE.APP_LAUNCH,
                    fireBaseSource: FIREBASE.VALUE.HOME,
                    ComponentName: MINI_SUBSCRIPTION.SELECTION_DRAWER
                });
            })
        } else if (shouldShowSubscription && !isEmpty(packListingData) && !isManagedApp) {
            setTimeout(async () => {
                await loginInFreemium({
                    openPopup,
                    closePopup,
                    openLoginPopup,
                    source: MIXPANEL.VALUE.APP_LAUNCH,
                    fireBaseSource: FIREBASE.VALUE.HOME,
                    ComponentName: MINI_SUBSCRIPTION.PLAN_SELECT
                });
            })
        }

    }
}

export const isNotRedirectedFromManageApp = () => {
    let searchParams = getSearchParamsAsObject();
    let statusInfo = searchParams?.hasOwnProperty('status'),
        cartId = searchParams?.hasOwnProperty('cartId'),
        tickTick = searchParams?.hasOwnProperty("tickTick")

    return !(tickTick || (statusInfo && cartId));
}

export const showLanguagePopupInSmall = (current) => {
    let { location: { pathname } } = current?.props;
    const urlArr = pathname.split("/");
    const urls = [URL.HELP_CENTER, URL.SUBSCRIPTION, URL.SUBSCRIPTION_TRANSACTION_REDIRECT, URL.SUBSCRIPTION_TRANSACTION, URL.BALANCE_INFO, URL.TRANSACTIONS];
    let isSSOPaymentValidateWebURL = isWebSmallLinkPayment(location);

    let showLanguageMiniDrawer = false;
    let userLanguage = JSON.parse(getKey(LOCALSTORAGE.PREFERRED_LANGUAGES));
    let deviceRemoved = JSON.parse(getKey(LOCALSTORAGE.DEVICE_REMOVED)) === true;

    if (isMobile.any()) {
        showLanguageMiniDrawer = isEmpty(userLanguage) && !urls.some((url) => url === urlArr[1]) && !isSSOPaymentValidateWebURL;
    } else {
        showLanguageMiniDrawer = isEmpty(userLanguage) && !urls.some((url) => url === urlArr[1]) && !deviceRemoved;
    }
    return showLanguageMiniDrawer;
};

export const checkHomePage = (current, isNewHeroBanner = false) => {
    let {
        location: { pathname },
    } = current?.props;
    const urlArr = pathname.split("/");

    if (isNewHeroBanner && [`${URL.HOME}`, `${URL.MOVIES}`, `${URL.TV_Shows}`, `${URL.KIDS}`, `${URL.PARTNER}`, `${URL.CATEGORIES}`, URL.LIVE_TV, URL.SPORTS].includes(urlArr[1].toLowerCase())
        || [`${URL.DEFAULT}`].includes(pathname.toLowerCase())) {
        return true
    }
    if (urlArr.includes(URL.BROWSE_BY_GENRE) && urlArr.includes(BROWSE_TYPE.GENRE.toLowerCase())) {
        return false
    }
    if ([`${URL.HOME}`, `${URL.MOVIES}`, `${URL.TV_Shows}`, `${URL.KIDS}`, `${URL.PARTNER}`, `${URL.BROWSE_BY_GENRE}`,`${URL.BROWSE_BY_LANGUAGE}`, `${URL.CATEGORIES}`, `${URL.FAVOURITES_SEE_ALL}`].includes(urlArr[1].toLowerCase())
        || [`${URL.DEFAULT}`].includes(pathname.toLowerCase())) {
        return true;
    }
};

export const accordingToMobileShowHideDownload = (current) => {
    const savedISDownload = JSON.parse(getKey(LOCALSTORAGE.IS_HIDE_DOWNLOAD_HEADER));
    current?.state?.windowContainerWidth > MOBILE_BREAKPOINT ? callBackToHideDownload(true) : callBackToHideDownload(savedISDownload);
}

export const callBackToHideDownload = (data) => {
    store.dispatch(isHideDownloadHeaderAction(data));
}

export const campaignPageFlow = async (current) => {
    let { location: { state }, history } = current?.props;
    let reduxState = store.getState();
    let packListingData = get(reduxState.subscriptionDetails, 'packListingData', []);
    if (state?.isExplorePlans && !isEmpty(packListingData) && state?.isHigher) {
        await loginInFreemium({
            openPopup,
            closePopup,
            ComponentName: MINI_SUBSCRIPTION.CHANGE_TENURE,
            isFromCampaign: true,
            selectedPlan: state?.selectedPack
        })
    }
    else if (state?.isTenure && !isEmpty(packListingData)) {
        await loginInFreemium({
            openPopup, 
            closePopup, 
            ComponentName: MINI_SUBSCRIPTION.CHANGE_TENURE, 
            isFromCampaign: true, 
            selectedPlan: state?.selectedPack
        });
    }
    else if (state?.isExplorePlans && !isEmpty(packListingData)) {
        handleNativeFlow(history)
        // redirectToMangeApp(MIXPANEL.VALUE.CAMPAIGN)
    }
    else {
        return
    }
}

export const showRegionalAppNudge = (history) => {
    const state = store.getState(),
        configResponse = get(state.headerDetails, "configResponse"),
        currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data'),
        nudgeStatus = get(state.subscriptionDetails, 'regionalNudgeStatus');
    if (!nudgeStatus) {
        return false
    }
    let { location: { pathname } } = history,
        nudgeFrequency = Number(get(configResponse, 'data.config.regionalAppFrequency')),
        urlArr = pathname.split("/"),
        homePageFrequency = JSON.parse(getKey(LOCALSTORAGE.NUDGE_LAUNCH_COUNTER)),
        isNudgeShow = homePageFrequency !== null && homePageFrequency % nudgeFrequency === 0;
    return ([URL.HOME].includes(urlArr[1]) || isEmpty(urlArr[1])) && isNudgeShow && !!get(currentSubscription, 'regionalAppNudge.enableRegionalAppNudge')
}

export const callMediaReadyConfig = () => {
    let durationForMRPollingInMins = JSON.parse(getKey(LOCALSTORAGE.MEDIAREADY_CONFIG_CALLING_DURATION));
    const startTime = moment(JSON.parse(getKey(LOCALSTORAGE.MEDIAREADY_CONFIG_CALLED_TIME)));
    const endTime = moment(new Date());
    const durationInBetween = moment.duration(endTime.diff(startTime));
    const remainingTimeInMins = Math.round(durationInBetween.asMinutes());
    console.log("MR Polling", remainingTimeInMins, startTime.format('h:mma'), endTime.format('h:mma'))
    return remainingTimeInMins == durationForMRPollingInMins;
}

/**
* 
* @param {*} fetchAccountPubnub 
* @returns On App refresh check if switch is needed from rmn channel to accountChannel or vice versa
*/
export const fetchAccountPubnub = async () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    if (userInfo?.dthStatus !== DTH_TYPE.DTH_W_BINGE_OLD_USER) {
        await store.dispatch(fetchGlobalConfig(GLOBAL_CONFIG_SOURCE.SPLASH));
        let state = store.getState();
        let accountPubnubDetails = get(state, "headerDetails.accountPubnubDetails");
        if (accountPubnubDetails?.code === 0 && accountChannelToBeSubscribed(accountPubnubDetails?.data)) {
            switchToAccountChannel(accountPubnubDetails?.data?.channelName);
        } else {
            switchToRMNChannel();
        }
    }
}

export const isSSR = () => {
    let baseElement = document.querySelector("base");
    return !!baseElement
}